import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, isDevMode   } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ChatModule } from './chat/chat.module';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgbModule,NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { PatrocinadoresComponent } from './home-page/patrocinadores/patrocinadores.component';
import { NovidadeComponent } from './home-page/novidade/novidade.component';
import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AtividadesComponent } from './home-page/atividades/atividades.component';
import { TecnologiasSociasComponent } from './home-page/tecnologias-socias/tecnologias-socias.component';
import { CarregamentoPadraoComponent } from './carregamento-padrao/carregamento-padrao.component';
import { CadastrarUsuarioComponent } from './cadastro-usuario/cadastrar-usuario/cadastrar-usuario.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuComponent } from './modulos/menu/menu.component';
import { IndexComponent } from './modulos/index/index.component';
import { EmailCheckComponent } from './cadastro-usuario/email-check/email-check.component';
import { AtualizarCadastroComponent } from './cadastro-usuario/atualizar-cadastro/atualizar-cadastro.component';
import { UsuarioComponent } from './modulos/usuario/usuario.component';
import { ModalRecarregarHidricasComponent } from './modais/modal-recarregar-hidricas/modal-recarregar-hidricas.component';
import { TesteComponent } from './teste/teste.component';
import { AprendaComponent } from './modulos/aprenda/aprenda.component';
import { MonitoreComponent } from './modulos/monitore/monitore.component';
import { MapeieComponent } from './modulos/mapeie/mapeie.component';
import { ModalAtividadesUsuariosComponent } from './modais/modal-atividades-usuarios/modal-atividades-usuarios.component';
import { NovidadesComponent } from './modulos/novidades/novidades.component';
import { ModalNovidadesComponent } from './modais/modal-novidades/modal-novidades.component';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { CustomDatepickerI18nService } from 'src/services/CustomDatepickerI18nService';
import { EmailComponent } from './cadastro-usuario/email/email.component';
import { AlterarSenhaComponent } from './cadastro-usuario/alterar-senha/alterar-senha.component';
import { EmailCheckEsqueciSenhaComponent } from './cadastro-usuario/email-check-esqueci-senha/email-check-esqueci-senha.component';
import { ServiceWorkerModule } from '@angular/service-worker';
registerLocaleData(ptBr);
import { TextMaskModule } from 'angular2-text-mask';
import { MaskPhoneNumberDirective } from '../diretivas/mask-phone-number.directive';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http);
}

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { ModelComponent } from './modulos/aprenda/modal/model.component';
import { AprendaStorysComponent } from './modulos/aprenda/aprenda-storys/aprenda-storys.component';
import { ModalNotificationComponent } from './modais/modal-notificaton/modal-notificaton.component';
import { HomeSpComponent } from './home-sp/home-sp.component';
import { SobreRecarregaSpComponent } from './home-sp/sobre-recarrega-sp/sobre-recarrega-sp.component';
import { TecnologiasSociasSpComponent } from './home-sp/tecnologias-socias-sp/tecnologias-socias-sp.component';
import { PatrocinadoresSpComponent } from './home-sp/patrocinadores-sp/patrocinadores-sp.component';
import { HomeCeComponent } from './home-ce/home-ce.component';
import { PatrocinadoresCeComponent } from './home-ce/patrocinadores-ce/patrocinadores-ce.component';
import { SobreRecarregaCeComponent } from './home-ce/sobre-recarrega-ce/sobre-recarrega-ce.component';
import { TecnologiasSociaisCeComponent } from './home-ce/tecnologias-sociais-ce/tecnologias-sociais-ce.component';
import { RotaHomeComponent } from './modulos/rota-home/rota-home.component';
import { ModalMuralComponent } from './modais/modal-mural/modal-mural.component';
import { HortasComponent } from './modulos/hortas/hortas.component';
import { MapaComponent } from './mapa/mapa.component';
import { MonitoreFormsComponent } from './modulos/monitore/monitore-forms/monitore-forms.component';
import { MenuDeskComponent } from './menu-desk/menu-desk.component';
import { CaireComponent } from './home-sp/caire/caire.component';
initializeApp(environment.firebaseConfig);

@NgModule({
  declarations: [
    MaskPhoneNumberDirective,
    AppComponent,
    PatrocinadoresComponent,
    NovidadeComponent,
    LoginComponent,
    HomePageComponent,
    AtividadesComponent,
    TecnologiasSociasComponent,
    CarregamentoPadraoComponent,
    CadastrarUsuarioComponent,
    MenuComponent,
    IndexComponent,
    EmailCheckComponent,
    AtualizarCadastroComponent,
    UsuarioComponent,
    ModalRecarregarHidricasComponent,
    TesteComponent,
    AprendaComponent,
    MonitoreComponent,
    MapeieComponent,
    ModalAtividadesUsuariosComponent,
    NovidadesComponent,
    ModalNovidadesComponent,
    EmailComponent,
    AlterarSenhaComponent,
    EmailCheckEsqueciSenhaComponent,
    ModelComponent,
    AprendaStorysComponent,
    ModalNotificationComponent,
    HomeSpComponent,
    SobreRecarregaSpComponent,
    TecnologiasSociasSpComponent,
    PatrocinadoresSpComponent,
    HomeCeComponent,
    PatrocinadoresCeComponent,
    SobreRecarregaCeComponent,
    TecnologiasSociaisCeComponent,
    RotaHomeComponent,
    ModalMuralComponent,
    HortasComponent,
    MapaComponent,
    MonitoreFormsComponent,
    MenuDeskComponent,
    CaireComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ChatModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),    
    TextMaskModule,
  ],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
