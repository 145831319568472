import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MonitoreService } from 'src/services/monitore.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Importando FormBuilder e FormGroup
import { DbPwaService } from 'src/services/db-pwa.service';

@Component({
  selector: 'app-monitore-forms',
  templateUrl: './monitore-forms.component.html',
  styleUrls: ['./monitore-forms.component.css']
})
export class MonitoreFormsComponent {

  enviandoDados: boolean = false;
  Forms: any;
  formData: FormGroup;;
  camposVazios: string[] = [];
  opcoesSelecionadas: { [id: string]: string[] } = {};
  userID: number;
  formid: string
  localizacao: string;

  imagemUrls: { [key: string]: string } = {};
  fotoInput: HTMLInputElement | undefined;

  isOnline: boolean;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private monitoreService: MonitoreService,
    private formBuilder: FormBuilder,
    private DBpwa: DbPwaService
  ) {
  }

  ngOnInit() {
    console.log('console log versão atualizada')
    this.userID = parseInt(sessionStorage.getItem('userID'), 10);
    this.route.params.subscribe((params) => {
      const id_forms = params['id']
      this.formid = id_forms
      this.getByIDPerguntasFormulario(id_forms)
    });



    

    this.isOnline = navigator.onLine;
    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });

  }

  getOfflineDados() {
    this.DBpwa.getAll().then((result: any) => {
      if (result && Array.isArray(result.rows)) {

        result.rows.forEach((row: any) => {
          if (row && row.doc) {
            const doc = row.doc;
            console.log(doc.respostas)
            doc.respostas.forEach(resposta => {

              
              const pergunta = this.Forms.find(form => form.id === resposta.pergunta_id);

              if (pergunta) {
                // Se encontrou a pergunta, adiciona a resposta à pergunta
                pergunta.resposta = resposta.resposta;
                pergunta.resposta_id = resposta.resposta_id;
              }


            });

          }
        });
      }
    });
  }

  getByIDPerguntasFormulario(id_forms) {
    this.monitoreService.getByIDPerguntasFormulario(id_forms).subscribe(
      (data) => {
        this.Forms = data
        this.inicializarFormData(data);

        if (this.isOnline) {
          this.getByIDRespostasFormulario(id_forms)
          console.log('carregando dados  online')

        } else {
          this.getOfflineDados();
          this.getByIDRespostasFormulario(id_forms)


        }
      },
      (error) => {
        console.error('Erro ao buscar monitore:', error);

      }
    );
  }


  getByIDRespostasFormulario(id_forms) {
    this.monitoreService.ListarRespostasByIdForm(this.userID, id_forms).subscribe(
      (respostas: any[]) => {
        respostas.forEach(resposta => {
          const pergunta = this.Forms.find(form => form.id === resposta.pergunta_id);
          if (pergunta) {
            pergunta.resposta = resposta.resposta;
            pergunta.resposta_id = resposta.resposta_id;
          }
        });
      },
      (error) => {
        console.error('Erro ao buscar monitore:', error);
        console.log(error.status)
        if (error.status == 404) {

        }
      }
    );
  }

  inicializarFormData(dynamicValues: any, offlineData: any = null) {
    const formControls = {};

    dynamicValues.forEach(form => {
      formControls[form.tipo + '_' + form.id] = ['', Validators.required];
    });

    this.formData = this.formBuilder.group(formControls);

    if (offlineData) {
      Object.keys(offlineData).forEach(key => {
        const control = this.formData.get(key);
        if (control) {
          control.setValue(offlineData[key]);
        }
      });
    }
  }
  onCheckboxChange(isChecked: boolean, opcao: string, perguntaId: number) {
    if (isChecked) {
      if (!this.opcoesSelecionadas[perguntaId]) {
        this.opcoesSelecionadas[perguntaId] = [];
      }
      this.opcoesSelecionadas[perguntaId].push(opcao);
    } else {
      if (this.opcoesSelecionadas[perguntaId]) {
        const index = this.opcoesSelecionadas[perguntaId].indexOf(opcao);
        if (index !== -1) {
          this.opcoesSelecionadas[perguntaId].splice(index, 1);
        }
      }
    }
  }


  enviarRespostas() {
    this.enviandoDados = true;
    const formData = new FormData();

    this.camposVazios = [];
    const payload = {
      usuario_id: this.userID,
      respostas: []
    };

    Object.keys(this.formData.controls).forEach(key => {

      if (key.includes('imagem_')) {
        return; // Ignora o processamento da pergunta de tipo imagem
      }

      const pergunta_id = parseInt(key.split('_')[1]);
      const control = this.formData.get(key);

      console.log(key)

      if (key.includes('checkBox')) {
        const opcoesSelecionadas = this.opcoesSelecionadas[pergunta_id];
        if (opcoesSelecionadas !== undefined) {

          formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
          formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
          formData.append(`respostas[${payload.respostas.length}][resposta]`, opcoesSelecionadas?.join(', '));

          payload.respostas.push({
            pergunta_id: pergunta_id,
            formulario_id: this.formid,
            resposta: opcoesSelecionadas?.join(', ')
          });
        }
      }

      else if (control.value) {

        formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
        formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
        formData.append(`respostas[${payload.respostas.length}][resposta]`, control.value);

        payload.respostas.push({
          pergunta_id: pergunta_id,
          formulario_id: this.formid,
          resposta: control.value
        });
      }
    });

    formData.append('usuario_id', payload.usuario_id.toString());

    console.log('Imprimindo FormData:');
    console.log('---------------------');
    formData.forEach((value, key) => {
      console.log(key, ':', value);
    });
    console.log('---------------------');


    let formHasValue = false;

    Object.keys(this.formData.controls).forEach(key => {
      const control = this.formData.get(key);
      if (control && control.value) {
        formHasValue = true;
      }
    });

    if (!formHasValue) {
      alert("Não há valores preenchidos no formulário.");
      this.enviandoDados = false;
      return;
    }


    this.monitoreService.enviarRespostas(formData).subscribe(
      (response) => {
        alert(response.message)
        this.irParaMonitore()
      },
      (error: any) => {
        this.enviandoDados = false;

        //Sem internet slavando localmente...
        if (error.status == 0) {
          this.DBpwa.addTask(payload)
          alert('Respostas salvas localmente.')
          this.irParaMonitore()
        }

        console.error('Erro ao salvar dados do formulário:', error);
        if (error.status == 409) {
          alert(error.error.message)
        }

      }
    );
  }


  irParaMonitore() {
    this.router.navigate(['/modulos/monitore']);
  }

  obterGeolocalizacao(perguntaId: any): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          this.formData.get('Geolocalizacao_' + perguntaId).setValue(`Latitude: ${latitude}, Longitude: ${longitude}`);
          this.localizacao = `Latitude: ${latitude}, Longitude: ${longitude}`;

        },
        (error) => {
          this.formData.get('Geolocalizacao_' + perguntaId).setValue(`Erro ao obter localização`);
          this.localizacao = 'Erro ao obter localização';
          console.error('Erro ao obter geolocalização:', error.message);
        }
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
    }
  }



  removerFoto(perguntaId: string) {
    delete this.imagemUrls[perguntaId];
    const input: HTMLInputElement | null = document.getElementById('imagemInput_' + perguntaId) as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }

  onFileSelected(event: any, pergunta_id: any) {

    if(!this.isOnline){
      alert('Por favor se conecte a internet, para poder enviar fotos.')
      return;
    }
    const file: File = event.target.files[0];
    const maxSize = 10 * 1024 * 1024;  // 10 MB


    if (file.size > maxSize) {
      alert('O arquivo é muito grande. O tamanho máximo permitido é 4MB.');
      return;
    }
    if (file) {
    
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.imageSrc = e.target.result;
        this.fileImagem = event.target
        this.showModal = true;
        this.perguntaId = pergunta_id;
      };
      reader.readAsDataURL(file);

    }

  }

  showModal: boolean = false;
  perguntaId: '';
  fileImagem: any;
  isLoading: boolean = false;
  imageSrc: '';

  cancelUpload() {
    this.showModal = false;
    this.perguntaId = ''
    this.imageSrc = ''
  }

  confirmUpload() {
    console.log('novo file')
    console.log(this.fileImagem)
    this.isLoading = true;
    this.uploadImagemAws(this.fileImagem, this.perguntaId);
  }

  clearImage() {
    this.imageSrc = null; 
    this.fileImagem = null;  
    this.showModal = false;  
    this.perguntaId = null;  

    // Limpa o campo de input (desmarcar o arquivo)
    const fileInput: HTMLInputElement = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = '';  // Limpa a seleção do arquivo
    }
  }

  uploadImagemAws(inputFile, pergunta_id) {

    const formData = new FormData();
    const payload = {
      usuario_id: this.userID,
      respostas: []
    };

    const file = inputFile.files[0];

    formData.append(`respostas[${payload.respostas.length}][pergunta_id]`, pergunta_id.toString());
    formData.append(`respostas[${payload.respostas.length}][formulario_id]`, this.formid.toString());
    formData.append(`respostas[${payload.respostas.length}][resposta]`, file);
    formData.append('imagem_' + pergunta_id, file);
    payload['imagem_' + pergunta_id] = file;

    payload.respostas.push({
      pergunta_id: pergunta_id,
      formulario_id: this.formid,
      resposta: file
    });

    formData.append('usuario_id', payload.usuario_id.toString());


    this.monitoreService.enviarRespostas(formData).subscribe(
      (response) => {
        const imageUrl = response.data[0].resposta;

        this.isLoading = false;
        this.showModal = false;
        this.enviandoDados = false;
        // Atualiza o campo 'resposta' no formulário
        this.clearImage()
        const form = this.Forms.find(f => f.id === pergunta_id);
        if (form) {
          form.resposta = imageUrl;
        }
      },
      (error: any) => {
        this.isLoading = false;

        this.enviandoDados = false;
        this.showModal = false;
        console.error('Erro ao salvar dados do formulário:', error);
        alert(error.error.message)


      }
    );
  }


}
