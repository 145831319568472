<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">



<!-- Modal de Confirmação de Envio -->
<div *ngIf="showModal" class="modal-overlay">
  <div class="modal-content">
    <div class="modal-header">
      <h3>Confirmar envio da imagem</h3>
    </div>
    <div class="modal-body">
      <img [src]="imageSrc" alt="Imagem selecionada" class="modal-image" />
    </div>
    <div class="modal-footer">
      <button class="cancel-btn" (click)="cancelUpload()">Cancelar</button>
      <button class="confirm-btn" (click)="confirmUpload()">Enviar</button>
    </div>
    <div *ngIf="isLoading" class="loading-overlay">
      <div class="loader"></div> Enviando...
    </div>
  </div>
</div>


<div *ngIf="Forms" class="backgroud">

  <div class="container-title background-color">
    <h1 class="title">Monitore</h1>
    <img (click)="irParaMonitore()" class="seta" src="./assets/seta.png" />
  </div>

  <br />
  
  <form class="form-all" [formGroup]="formData" (ngSubmit)="enviarRespostas()">

    <div *ngFor="let form of Forms">
      <div *ngIf="form.tipo === 'normal'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <input class="input form-control" [ngClass]="{ 'campo-vazio': camposVazios.includes('normal_' + form.id) }"
            formControlName="{{ 'normal_' + form.id }}" type="text" placeholder="" required />
          <span *ngIf="camposVazios.includes('normal_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div *ngIf="form.tipo === 'data'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <input class="input form-control" formControlName="{{ 'data_' + form.id }}" type="date" />
          <span *ngIf="camposVazios.includes('data_' + form.id)" class="campo-vazio-mensagem">Campo obrigatório*</span>
        </div>

      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'Geolocalizacao'">
        <label class=" inputopcoes label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="loc espaco-top" (click)="obterGeolocalizacao(form.id )"><i class="fa-solid fa-location-dot">
            </i>Localização</div>
          <div class="label-text">{{ localizacao }}</div>
          <input class="input form-control" formControlName="{{ 'Geolocalizacao_' + form.id }}" type="hidden" />
          <span *ngIf="camposVazios.includes('Geolocalizacao_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div *ngIf="form.tipo === 'lista'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <select class="input form-control" formControlName="{{ 'lista_' + form.id }}" [(ngModel)]="opcaoSelecionada">
            <option [value]="opcao" *ngFor="let opcao of form.opcoes; first as isFirst" [selected]="opcao">{{ opcao }}
            </option>
          </select>
          <span *ngIf="camposVazios.includes('lista_' + form.id)" class="campo-vazio-mensagem">Campo obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'alternativa'">
        <label class="question">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="inputopcoes" *ngFor="let opcao of form.opcoes">
            <label class="radio-label">
              <input formControlName="{{ 'alternativa_' + form.id }}" type="radio" [value]="opcao">{{ opcao }}
            </label>
          </div>
          <span *ngIf="camposVazios.includes('alternativa_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'checkBox'">
        <label class="label-text">{{ form.pergunta }}</label>
        <input *ngIf="form.resposta !== undefined" class="input form-control" [value]="form.resposta" disabled />
        <div *ngIf="form.resposta == undefined">
          <div class="inputopcoes" *ngFor="let opcao of form.opcoes">
            <input class="inputchebox" formControlName="{{ 'checkBox_' + form.id }}" type="checkbox" [value]="opcao"
              (change)="onCheckboxChange($event.target.checked, opcao, form.id)">
            {{ opcao }}
          </div>
          <span *ngIf="camposVazios.includes('checkBox_' + form.id)" class="campo-vazio-mensagem">Campo
            obrigatório*</span>
        </div>
      </div>


      <div class="largura-formulario" *ngIf="form.tipo === 'imagem'">
        <label for="imagemInput" class="label-text inputopcoes">{{ form.pergunta }}</label>

        <div *ngIf="form.resposta!== undefined">
          <img [src]="form.resposta " alt="Imagem" class="foto" />
        </div>

        <label *ngIf="form.resposta == undefined" for="{{'imagem_'+form.id}}" class="bt-foto espaco-top"><i
            class="fa-regular fa-images"></i>Selecionar
          Foto</label>
        <input id="{{'imagem_'+form.id}}" #fotoInput formControlName="{{ 'imagem_' + form.id }}" type="file"
          (change)="onFileSelected($event, form.id)" style="display: none;">
        <div *ngIf="imagemUrls[form.id]" class="foto-container">
          <img [src]="imagemUrls[form.id]" alt="Imagem" class="foto" />
          <i (click)="removerFoto(form.id )" class="fa-solid fa-xmark botao-remover"></i>
        </div>
        <span *ngIf="camposVazios.includes('imagem_' + form.id)" class="campo-vazio-mensagem">Campo obrigatório*</span>

      </div>

    </div>

    <div *ngIf="camposVazios.length > 0">
      <p class="campo-vazio-mensagem">Por favor, preencha todos os campos!</p>
      <!-- <p>Campos vazios:</p>
      <ul>
        <li *ngFor="let campo of camposVazios">{{ campo }}</li>
      </ul> -->
    </div>
    <button type="submit" class=" custom-button">
      <div class="container-btn-edit">
        <p class="btn-edit">SALVAR </p>
        <div class="loading-icon" *ngIf="enviandoDados"></div>

      </div>
    </button>

    <div (click)="irParaMonitore()" class="root-voltar">
      <h5 class="text-voltar">
        < VOLTAR </h5>
    </div>
  </form>
</div>