<app-carregamento-padrao *ngIf="!isLoading"></app-carregamento-padrao>
<app-menu-desk></app-menu-desk>


<div *ngIf="isLoading" class="background2">
  <div class="header-text">Monitore</div>
</div>

<div class="content-box ">

  <div *ngFor="let item of DadosMonitore" class="theme">
    <div (click)="irParaTelaMonitore(item.id)" class="content-background1"
      [ngStyle]="{ 'background-color': item.cor }">
      <div class="content-image">
        <img class="content-img" [src]="item.caminho_imagem" alt="Imagem" />
      </div>
      <div class="conteudo-atividade">
        <div class="content-text">{{item.nome}}</div>
        <div class="itens-icones">

          <div *ngIf="item.respondido =='respondido'" class="icon1">
            <div class="circle">
              <i class="fa-regular fa-eye"></i>
            </div>
          </div>

          <div *ngIf="item.respondido =='parcial'" class="icon1">
            <div class="circle">
              <i class="fa-solid fa-exclamation"></i>
            </div>
          </div>
          <p *ngIf="item.respondido == 'parcial'">Algumas perguntas ainda não foram respondidas.</p>

          <div *ngIf="!item.respondido && item.respondidoOffline" class="icon1">
            <div class="circle">
              <i class="fa-solid fa-exclamation"></i>
            </div>
          </div>
        <p *ngIf="!item.respondido && item.respondidoOffline">  Aguardando conexão para sincronizar... </p>
        </div>
      </div>
    </div>
  </div>


  <!-- essa div eh para da espaco para o menu ficar em baixo... -->
  <div>
    <p class="">&nbsp;</p>
    <p class="">&nbsp;</p>
  </div>
  <app-menu></app-menu>

</div>