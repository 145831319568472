import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MonitoreService } from 'src/services/monitore.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Importando FormBuilder e FormGroup
import { DbPwaService } from 'src/services/db-pwa.service';




@Component({
  selector: 'app-monitore',
  templateUrl: './monitore.component.html',
  styleUrls: ['./monitore.component.css']
})
export class MonitoreComponent {

  userID: number;
  isLoading: boolean = false;
  isOnline: boolean;
  formData: FormGroup;

  constructor(private router: Router, private monitoreService: MonitoreService, private DBpwa: DbPwaService) {
  }

  DadosMonitore: any;
  ngOnInit() {
    this.userID = parseInt(sessionStorage.getItem('userID'), 10);

    this.isOnline = navigator.onLine;
    window.addEventListener('online', () => {
      this.isOnline = true;
    });

    window.addEventListener('offline', () => {
      this.isOnline = false;
    });

    const isOnline = window.navigator.onLine;


    if (isOnline) {
      console.log('online')
    } else {
      console.log('offline')
    }

    this.monitoreService.ListarFormulario(this.userID).subscribe(
      (data) => {
        this.DadosMonitore = data;
        console.log(this.DadosMonitore)
        this.isLoading = true;
        this.processarDadosMonitoreOffline();

      },
      (error) => {
        this.isLoading = true;

        console.error('Erro ao buscar monitore:', error);
      }
    );

    if (isOnline) {

      this.DBpwa.getAll().then((result: any) => {

        if (result && Array.isArray(result.rows)) {
          result.rows.forEach((row: any) => {
            if (row && row.doc) {
              const doc = row.doc;
              this.enviarRespostasOffline(doc);
            } else {
              console.error('Erro: O objeto retornado não está no formato esperado.');
            }
          });
        } else {
          console.error('Erro: O resultado retornado não está no formato esperado.');
        }
      }).catch(error => {
        // Trate o erro, se houver
        console.error('Erro ao obter informações:', error);
      });


    }
  }

  irParaMonitore() {
    this.router.navigate(['/modulos/monitore']);
  }

  /*Se tiver algumas respostas salvas do banco local sincroniza com nuvem, e remove do banco local*/
  enviarRespostasOffline(form) {

    const formData = new FormData();

    Object.keys(form).forEach(key => {
      if (key === 'respostas') {
        // Se a propriedade é 'respostas', trata-a separadamente
        form.respostas.forEach((resposta: any, index: number) => {
          Object.keys(resposta).forEach(respostaKey => {
            // Se a resposta é um arquivo, adicione-a ao FormData
            if (resposta[respostaKey] instanceof File) {
              formData.append(`respostas[${index}][${respostaKey}]`, resposta[respostaKey]);
            } else {
              formData.append(`respostas[${index}][${respostaKey}]`, resposta[respostaKey]);
            }
          });
        });
      } else {
        formData.append(key, form[key]);
      }
    });

    // console.log('Imprimindo FormData:');
    // console.log('---------------------');
    // formData.forEach((value, key) => {
    //   console.log(key, ':', value);
    // });
    // console.log('---------------------');

    this.monitoreService.enviarRespostas(formData).subscribe(
      (response) => {
        alert(response.message);
        this.DBpwa.clearTask(form._id)
        this.irParaMonitore()
      },
      (error: any) => {
        // console.error('Erro ao salvar dados do formulário:', error);
        if (error.status == 409) {
          console.log(error.error.message);
        } else {
        }
      }
    );
  }

  processarDadosMonitoreOffline() {
    if (this.DadosMonitore && !this.isOnline) {
      console.log('Verificando dados ')
      this.DBpwa.getAll().then((result: any) => {
        if (result && Array.isArray(result.rows)) {
          result.rows.forEach((row: any) => {
            if (row.doc.respostas && row.doc.respostas.length > 0) {
              console.log('testa o offline')
              const objsParaAtualizar = this.DadosMonitore.filter((obj: any) => obj.id == row.doc.respostas[0].formulario_id);
              objsParaAtualizar.forEach((obj: any) => obj.respondidoOffline = true);
            } else {
              console.log("A propriedade 'respostas' não está definida ou está vazia.");
            }
          });
        } else {
          console.error('Erro: O resultado retornado não está no formato esperado.');
        }
      }).catch(error => {
        // Trate o erro, se houver
        console.error('Erro ao obter informações:', error);
      });
    }
  }
  irParaTelamodulosIndex() {
    this.router.navigate(['/modulos/index']);
  }


  irParaTelaMonitore(id: number) {

    this.irParaTelaMonitoreForms(id)

  }

  irParaTelaMonitoreForms(id: number) {
    this.router.navigate(['/modulos/monitore/monitore_forms', id]);
  }

}
