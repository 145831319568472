import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class MonitoreService {
  private apiUrl: string;
  private token = sessionStorage.getItem('token'); 

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  enviarRespostas(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 

    const url =`${this.apiUrl}/monitore/respostas`; 

    console.log(payload)
    return this.http.post<any>(url, payload, { headers });
  }

  enviarRespostaUnica(payload: any): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 

    const url =`${this.apiUrl}/monitore/salvar-resposta`; 

    console.log(payload)
    return this.http.post<any>(url, payload, { headers });
  }


  ListarRespostasByIdForm(user_id: number,formulario_id: number) {
    const url = `${this.apiUrl}/monitore/respostas/user/${user_id}/${formulario_id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 
    return this.http.get(url, { headers });
  }

  ListarFormulario(user_id: number) {
    const url = `${this.apiUrl}/formularios/${user_id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 
    return this.http.get(url, { headers });
  }

  getByIDPerguntasFormulario(formulario_id: number) {
    const url = `${this.apiUrl}/formularios/pergunta/${formulario_id}`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`); 
    return this.http.get(url, { headers });
  }

}
