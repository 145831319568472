import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { AuthGuard } from 'src/services/auth_guard';
import { CadastrarUsuarioComponent } from './cadastro-usuario/cadastrar-usuario/cadastrar-usuario.component';
import { chatIndexComponent } from './chat/chat-index/chat-index.component';
import { IndexComponent } from './modulos/index/index.component';
import { EmailCheckComponent } from './cadastro-usuario/email-check/email-check.component';
import { AtualizarCadastroComponent } from './cadastro-usuario/atualizar-cadastro/atualizar-cadastro.component';
import { UsuarioComponent } from './modulos/usuario/usuario.component';

import { TesteComponent } from './teste/teste.component';
import { AprendaComponent } from './modulos/aprenda/aprenda.component';
import { MonitoreComponent } from './modulos/monitore/monitore.component';
import { MapeieComponent } from './modulos/mapeie/mapeie.component';
import { NovidadesComponent } from './modulos/novidades/novidades.component';
import { ModalNovidadesComponent } from './modais/modal-novidades/modal-novidades.component';
import { EmailComponent } from './cadastro-usuario/email/email.component';
import { AlterarSenhaComponent } from './cadastro-usuario/alterar-senha/alterar-senha.component';
import { EmailCheckEsqueciSenhaComponent } from './cadastro-usuario/email-check-esqueci-senha/email-check-esqueci-senha.component';
import { ModelComponent } from './modulos/aprenda/modal/model.component';
import { AprendaStorysComponent } from './modulos/aprenda/aprenda-storys/aprenda-storys.component';
import { HomeSpComponent } from './home-sp/home-sp.component';
import { HomeCeComponent } from './home-ce/home-ce.component';
import { RotaHomeComponent } from './modulos/rota-home/rota-home.component';
import { HortasComponent } from './modulos/hortas/hortas.component';
import { MapaComponent } from './mapa/mapa.component';
import { MonitoreFormsComponent } from './modulos/monitore/monitore-forms/monitore-forms.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomePageComponent },
  { path: 'home_sp', component: HomeSpComponent },
  { path: 'home_ce', component: HomeCeComponent},
  { path: 'modulos/rota_home', component: RotaHomeComponent},

  { path: 'login', component: LoginComponent },
  { path: 'email', component: EmailComponent },
  { path: 'emailcheck', component: EmailCheckComponent},
  { path: 'atualizar_cadastro', component: AtualizarCadastroComponent},
  { path: 'model/novidades/:button', component: ModalNovidadesComponent},
  { path: 'alterar_senha/:id/:hash', component: AlterarSenhaComponent },
  { path: 'emailcheck_esqueciminhasenha', component: EmailCheckEsqueciSenhaComponent },
  { path: 'cadastro_usuario', component: CadastrarUsuarioComponent },
  { path: 'modulos/chat/:tipo', component: chatIndexComponent ,canActivate: [AuthGuard]},
  { path: 'modulos/index', component: IndexComponent ,canActivate: [AuthGuard]},
  { path: 'modulos/usuario', component: UsuarioComponent ,canActivate: [AuthGuard]},
  { path: 'modulos/aprenda', component: AprendaComponent,canActivate: [AuthGuard]},
  { path: 'modulos/aprenda/modal/:id', component: ModelComponent,canActivate: [AuthGuard]},
  { path: 'modulos/aprenda/storys/:id', component: AprendaStorysComponent,canActivate: [AuthGuard]},
  { path: 'modulos/monitore', component: MonitoreComponent,canActivate: [AuthGuard]},
  {path: 'modulos/monitore/monitore_forms/:id', component: MonitoreFormsComponent, canActivate: [AuthGuard]},

  { path: 'modulos/mapeie', component: MapeieComponent,canActivate: [AuthGuard]},
  { path: 'modulos/novidades', component: NovidadesComponent,canActivate: [AuthGuard]},
  {path: 'modulos/hortas', component: HortasComponent},

  { path: 'teste', component: MapaComponent},


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
